import axios from "axios"
var Config = require('../config.json')
import AuthService from '../service/auth'
var entity='Food';

const Service = {
    create: function(food) {
	var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.post(Config.api_url+'/'+entity,food,header);
    },
    getAll: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity,header);
    },
    getById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/'+id,header);
    },
    getByFilter: function(filter) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var qs = serialize(filter);
		return axios.get(Config.api_url+'/'+entity+'/filter/params?'+qs,header);
    },
    getByKeywords: function(keywords) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		var _keywords =keywords.replaceAll(' ','%20');
		return axios.get(Config.api_url+'/'+entity+'/search/keywords?keywords='+_keywords,header);
    },
    getTranslations: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/get/translations',header);
    },
    getFoodGroups: function() {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.get(Config.api_url+'/'+entity+'/get/foodGroups',header);
    },
    update: function(request) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.put(Config.api_url+'/'+entity+'/'+request._id,request,header);
    },
    deleteById: function(id) {
		var bearerToken =AuthService.getBearerToken();
		const header = {
			headers: { Authorization: 'Bearer '+bearerToken }
		};
		return axios.delete(Config.api_url+'/'+entity+'/'+id,header);
    }
};

function serialize(obj) {
	var str = [];
	for (var p in obj)
	if (obj.hasOwnProperty(p)) {
		str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	}
	return str.join("&");
}
		
export default Service;
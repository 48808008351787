import {useEffect,useState,useRef} from 'react';
import { useNavigate,useParams } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { convertToRaw,convertFromRaw, EditorState } from "draft-js";
//import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

import parse from 'html-react-parser';

import TopPrivateNavigation from '../components/TopPrivateNavigation.jsx'
import AppLogout from '../components/AppLogout.jsx'

//services
import AuthService from '../service/auth'
import ProjectService from '../service/Project'
import ClientService from '../service/Client'
import ClientLogService from '../service/ClientLog'
import ExportsService from '../service/Exports'
import ReportsService from '../service/Reports'

const formatDate=function(d){
	var d = new Date(d);
	Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}
    var dformat = [
               d.getDate().padLeft(),
			   (d.getMonth()+1).padLeft(),
               d.getFullYear()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');
	return dformat;
}

const ClientLogs = () => {
	
	const navigate = useNavigate();
	//protected page check if logged in
	const [currentUser,setCurrentUser]=useState({});
	const [projectData,setProjectData]=useState([]);
	const [projectFound,setProjectFound]=useState(false);
	
	const [clientData,setClientData]=useState([]);
	const [clientLoaded,setClientLoaded]=useState(false);
	const [clientLogData,setClientLogData]=useState([]);
		
	let params = useParams();
	let client_id =params.client_id;
	console.log('client_id',client_id);
	let project_id =ProjectService.getCurrentProject();//params.id;
	console.log('project_id',project_id);
		
	useEffect(() => {
		if(!AuthService.isUserLoggedIn()){
			// not logged in navigate back to login
			navigate("/login");
		}else{
				setCurrentUser(AuthService.getCurrentUser());
				var account_type=AuthService.getCurrentUser().__t;
				console.log('account_type',account_type);
				
				console.log(AuthService.getCurrentUser());
				var currentUser=AuthService.getCurrentUser();
				//expired Account
				if(currentUser.expired){
					var string = "account_type="+AuthService.getCurrentUser().__t+"&type=expired";
					console.log('string',string);
					var encodedString = btoa(string); // Base64 encode the String
					console.log('encodedString',encodedString);
					navigate("/restricted/"+encodedString);
					
				}else{//unverified account
					if(!currentUser.email_verified){
						var string = "account_type="+AuthService.getCurrentUser().__t+"&type=emaiverification";
						console.log('string',string);
						var encodedString = btoa(string); // Base64 encode the String
						console.log('encodedString',encodedString);
						navigate("/restricted/"+encodedString);
						
					}else{////force change Password
						if(currentUser.force_password_change){
							//not implemented	
						
						}else{
							//load project if set 
							if((typeof project_id !=="undefined")&&(project_id!==null)){//project filter
								loadProjectById(project_id);
							}
							if((typeof client_id !=="undefined")&&(client_id!==null)){
								console.log('not null or undefined client_id',client_id);
								loadClientById(client_id);
								loadClientLogById(client_id);
							}
							//load
							if(account_type=='Admin'){//Admin
						
							}else if(account_type=='Master'){//Master
							
								
							}else if(account_type=='Researcher'){//Researcher
								
							}
						}
					}
				}	
		}
	}, []);
	
		
	//load project
	function loadProjectById(project_id){
		ProjectService.getById(project_id).then(function(res){
		setProjectData(res.data);
		console.log(res.data);
		setProjectFound(true);
		}).catch(function(error){
			console.log(error);
			setProjectFound(false);
		});
	}
	
	//Client Log
	const [openNewClientLogModal, setNewClientLogModalOpen] = useState(false);
	const handleNewClientLogModalOpen = () => {
		setNewClientLogModalOpen(true);
	};

	//Change file type text dynamically in download dialog
	const [reportType, setReportType] = useState("");

	//Open/Close Report Download in Progress Dialog
	const [openDownloadModal, setDownloadModal] = useState(false);

	//Download report on download button click and close the modal.
	const handleDownloadModalClose = () => {
		setDownloadDisabled(true);
		setDownloadModal(false);
		window.open(reportUrl,"_blank");
	};

	//Enable/disable Download button 
	const [downloadDisabled, setDownloadDisabled] = useState(true);

	//date
	const [dateOfLog, setDateOfLog] = useState('');
	const handleDateOfLogChange = (event) => {
		console.log(event.$d);
		setDateOfLog(event.$d);
		const tempDate = new Date(event.$d);
		let day_index = tempDate.getDay();
		setDayOfWeek(daysOfWeek[day_index]);
	};
	
	//day of week
	const [daysOfWeek, setDaysOfWeek] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]);
	const [dayOfWeek, setDayOfWeek] = useState('');
	const handleDayOfWeekChange = (event) => {
		console.log(event.target.value);
		setDayOfWeek(event.target.value);
	};
	
	//Work / School day
	const [workSchoolDay, setWorkSchoolDay] = useState('');
	const handleWorkSchoolDayChange = (event) => {
		console.log(event.target.value);
		setWorkSchoolDay(event.target.value);
	};
	
	const handleNewClientLogModalClose = () => {
		setNewClientLogModalOpen(false);
	};
	
	//Notes
	const [tempData,setTempData]=useState(null);
	
	const tempContent ={"blocks":[{"key":"6vocr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}};
		
	const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(tempContent)));
	  const onEditorStateChange = function (editorState) {
		setEditorState(editorState);
		const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
		console.log(content);
		setTempData(convertToRaw(editorState.getCurrentContent()));
	};

	// //New report created
	// const handleDownloadReport = () => {
	// 	setDownloadDisabled(true);
	// 	setDownloadModal(false);
	// 	window.open(reportUrl,"_blank");
	// }

	//new client log
	const handleSubmitNewClientLog=(event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		var _tempClientLog={'client':client_id,'completed':false,'created_by':AuthService.getCurrentUser()._id};
		//form data 
		const data = new FormData(event.currentTarget);
		_tempClientLog.work_school_day =data.get('work_school_day');
		
		//other data
		_tempClientLog.date =dateOfLog;
		_tempClientLog.day_of_week =dayOfWeek;
		
		if(tempData !== null) {
			console.log(JSON.stringify(tempData));
			_tempClientLog.notes=JSON.stringify(tempData);
		}else{
			_tempClientLog.notes=JSON.stringify(tempContent);
		}
		console.log('_tempClientLog',_tempClientLog);

		ClientLogService.create(_tempClientLog).then(function(res){
			console.log(res.data);
			var _newClientLog= res.data;
			navigate("/client/log/"+_newClientLog._id);
		}).catch(function(error){
			console.log(error);
		});
		/*
		if((project.name)&&(project.description)){	
			createProject(project);
			setNewProjectModalOpen(false);		
		}else{
			setErrorStatus(true);
			setErrorMessage("Please ensure all required fields are completed correctly!!");
		}*/
	};
	
	const [ShowErrorMessage,setErrorStatus]=useState(false); 
	const [ErrorText,setErrorMessage]=useState(""); 
	
	const [ShowSuccessMessage,setSuccessStatus]=useState(false); 
	const [SuccessText,setSuccessMessage]=useState("");
	
	function loadClientById(client_id){
			ClientService.getById(client_id).then(function(res){
				setClientData(res.data);
				setClientLoaded(true);
				console.log(res.data);
				var _client= res.data;

				console.log('_client',_client);
			}).catch(function(error){
					console.log(error);
			});
	}
		/* client logs */		
		function loadClientLogById(client_id){
				ClientLogService.getByFilter({'client':client_id}).then(function(res){
					var _unsortedLog=res.data;
					var sortedLog = _unsortedLog.sort(function(a,b){
						return new Date(b.created_date) - new Date(a.created_date);
					});
					setClientLogData(sortedLog);
					console.log(res.data);
				}).catch(function(error){
					console.log(error);
				});
		}
		
	/* Download logs as csv file*/
	const [downloadUrl,setDownloadUrl]=useState("");
	const stateRef = useRef();
	stateRef.loaded = false;
	
	const handleTestDownload = (e) => {
		var _table ='client_log';
		var _filter={'_id':client_id};
	

		console.log('_filter',_filter);
		ExportsService.build(_table,_filter).then(function(res){
			setReportType("CSV");
			console.log(res.data);
			var filename =res.data.filename;
			var url=ExportsService.getUrl(filename);
			console.log('url',url);
			setDownloadUrl(url);
			
			var counter=0;
			var refreshIntervalId = setInterval(function(){
				checkIfBuilt(filename);
				if(stateRef.loaded){
					clearInterval(refreshIntervalId);
					window.open(url,'_blank');
				}
				if(counter==20){
					clearInterval(refreshIntervalId);
				}
				counter++;
			}, 1000);
						
		}).catch(function(error){
				console.log(error);
		});
	  };
	  
	  function checkIfBuilt(filename){
		  	ExportsService.exists(filename).then(function(res){
			if(res.data.exists){
				stateRef.loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	  }
	  
	/* generate report*/
	const [reportUrl,setReportUrl]=useState("");
	//const stateRef = useRef();
	stateRef.report_loaded = false;
	
	const handleGenerateReport = (e) => {
		var _opt={'type':'feedback','client_id':client_id};//no nested objects

		ReportsService.generateReport(_opt).then(function(res){
			setReportType("PDF");
			setDownloadModal(true);
			console.log(res.data);
			var filename =res.data.uniqueID+'.pdf';
			var url=ReportsService.getUrl(filename);
			console.log('url',url);
			setReportUrl(url);

			var counter=0;
			var refreshIntervalId = setInterval(function(){
				checkIfGenerated(filename);
				if(stateRef.report_loaded){
					clearInterval(refreshIntervalId);
					setDownloadDisabled(false);
				}
				if(counter==20){
					clearInterval(refreshIntervalId);
				}
				counter++;
			}, 1000);
						
		}).catch(function(error){
				console.log(error);
		});
	  };
	  
	  function checkIfGenerated(filename){
		  	ReportsService.exists(filename).then(function(res){
				console.log('res.data',res.data);
			if(res.data.exists){
				stateRef.report_loaded=true;
			}
		}).catch(function(error){
				console.log(error);
		});
	  }
	
  return(
  <>
	<TopPrivateNavigation/>
	<AppLogout>
	
		{/* error no project found */}
	{(!projectFound) ?
		<>
		
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
		 <Typography color="text.primary">ClientLogs</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
		
		
		</>
	:<></>}
	{/* if project is found */}
	{(projectFound) ?
	<>
		<br/>
		<Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          href="/projects"
        >
          Projects
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/project/${projectData._id}`}
        >
          {projectData.name}
        </Link>
		<Link
          underline="hover"
          color="inherit"
          href={`/client/view/${clientData._id}`}
        >
          {clientData.code}
        </Link>
		 <Typography color="text.primary">Logs</Typography>
      </Breadcrumbs>
	  
	  <br/>
	  <Divider />
		<br/>
	
	</>
	:<></>}
	
	<Button  variant="contained" endIcon={<AddIcon />}  onClick={handleNewClientLogModalOpen}>
			Create New Log  
		</Button>
		
			  {/* manage client log dialog */}
      <Dialog
        open={openNewClientLogModal}
      >
	  <Box component="form" onSubmit={handleSubmitNewClientLog} noValidate sx={{ mt: 1 }}>
        <DialogTitle>New Client Log</DialogTitle>
        <DialogContent>
          <DialogContentText>
      
          </DialogContentText>
		  { ShowErrorMessage &&
				<Alert variant="filled" severity="error">{ErrorText}</Alert>
			} 
			{ ShowSuccessMessage &&
				<Alert variant="filled" severity="success">{SuccessText}</Alert>
			}
			
			<LocalizationProvider dateAdapter={AdapterDayjs}>
			  <DemoContainer   components={['DatePicker']}>
				<DatePicker sx={{width:'500px'}} disableFuture value={dayjs(dateOfLog)} format="DD-MM-YYYY"  onChange={handleDateOfLogChange}  label="Date of log" />
			  </DemoContainer>
			</LocalizationProvider>
		  
		  <br/>
		  
			<FormControl sx={{ m: 1, width: '500px',margin:'0px' }}>
				  <InputLabel id="demo-select-large-label">Day Of Week</InputLabel>
				  <Select
					labelId="demo-select-small-label"
					id="demo-select-small"
					value={dayOfWeek}
					label="Day Of Week"
					onChange={(e) => handleDayOfWeekChange(e)}
				  >
				  {daysOfWeek.map((opt,index) => (
					  <MenuItem key={opt} value={opt}>{opt}</MenuItem>
				  ))}
				  </Select>
				</FormControl>

				<br/><br/>
				
				 <FormControl sx={{ m: 1, width: '500px',margin:'0px' }}>
				    <InputLabel id="demo-simple-select-label">Work / School day</InputLabel>
					  <Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={workSchoolDay}
						label="Work / School day"
						name="work_school_day"
						onChange={handleWorkSchoolDayChange}
					  >
						<MenuItem value={true}>Yes</MenuItem>
						<MenuItem value={false}>No</MenuItem>
					  </Select>
				</FormControl>
				
				
				<h3>Notes</h3>
				<Editor
				editorStyle={{ border: "1px solid grey" ,backgroundColor:"white",minHeight:"150px"}}
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={onEditorStateChange}
				/>

		<br/><br/>
				
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewClientLogModalClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
		</Box>
      </Dialog>
	  
		<Dialog
			open={openDownloadModal}
		>
        <DialogTitle>
          Generating {reportType} Report
        </DialogTitle>
        <DialogContent dividers>
			{openDownloadModal&&downloadDisabled ? 		
				<Box>
					<CircularProgress />
					<Typography gutterBottom>
						Please wait, this may take a moment...
					</Typography>
				</Box>	
				:
				<Typography gutterBottom>
					A Report has been generated. Click the button below to download your PDF.
				</Typography> 	
			}
			<br></br>
			<DialogActions>
				<Button disabled={downloadDisabled} onClick={handleDownloadModalClose} variant="contained" endIcon={<FileDownloadIcon />}>
					Download Report
				</Button>
			</DialogActions>
        </DialogContent>
      </Dialog>
	
	<br /><br />
	{(clientLogData.length==0) ?
	<>
		None found
	</>
	:<>
	
	  	<div style={{ width: '100%' }}>	
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
         // bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
	  {(currentUser.__t=='Master') ?
		<>
		  <Button onClick={(e) => handleTestDownload(e)} color="primary" variant="outlined"  endIcon={<FileDownloadIcon />}   size="small" >
			Download csv
	  </Button>&nbsp;
	  	<Button onClick={(e) => handleGenerateReport(e)} color="primary" variant="outlined"  endIcon={<FileDownloadIcon />}   size="small" >
			Generate report
	  </Button>&nbsp;
		
		</>:<></>}
		</Box>
    </div>
	
	
	<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Date created</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientLogData.map((log) => (
            <TableRow
              key={log._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left"><Link href={`/client/log/${log._id}`}>{log.name}</Link></TableCell>
              <TableCell align="left">{formatDate(log.created_date)}</TableCell>
              <TableCell align="right">
			  {(log.completed) ?
				<>
				Complete &nbsp; <CircleIcon  fontSize="small"  style={{  color: 'green',fontSize:"small" }} /> 
				</>
				:<>
				Pending &nbsp; <CircleIcon    style={{  color: 'orange',fontSize:"small" }} /> </>}
			  </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
	
	
	
	</>}
	 
	<br/><br/><br/>
	
	
	{/* Admin */}
	{(currentUser.__t=='Admin') ?
	<>
	
	</>
	:<></>}
	
	{/* Master */}
	{(currentUser.__t=='Master') ?
	<>
	
	</>
	:<></>}
	
	{/* Researcher */}
	{(currentUser.__t=='Researcher') ?
	<>
	
	</>
	:<></>}

	</AppLogout>
	</>
  ) 
  ;
};

export default ClientLogs;
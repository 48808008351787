//import React from 'react';
import Box from "@mui/material/Box";
const logo = require('../images/banner.png'); 
import ReactDOM, { Link } from "react-router-dom";

const styles = {
 logoImage:{
	width: '100%',
	height: '50px'}
};

const TopPublicNavigation = (props) => {
	
  return (
    <Box
	  display="flex"
	  justifyContent="center"
	  alignItems="center"
	  >
	  {props.Linked &&
			<Link to={props.LinkTo} style={{ textDecoration: 'none' }}>
				<img style={styles.logoImage} src={logo} />
			</Link>	
	   } 
	   
	   {!props.Linked &&
			<img style={styles.logoImage} src={logo} />
	   } 
		
	</Box>
  );
};

export default TopPublicNavigation;